import { Injectable } from '@angular/core';
import { Texts } from './texts';
import * as textsEn from './texts/texts-en.json';
import * as textsDe from './texts/texts-de.json';
import { TextsWrapper } from './textsWrapper';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  de: any = textsDe;
  en: any = textsEn;

  getTexts(
    language: string,
    adminSingular: string,
    adminPlural: string,
    userSingular: string,
    userPlural: string,
    deparmentSingular: string,
    departmentPlural: string,
  ): Texts {
    switch (language) {
      case 'de':
        var texts: string = JSON.stringify(this.de);
        return (
          JSON.parse(
            this.replaceTexts(
              texts,
              adminSingular,
              adminPlural,
              userSingular,
              userPlural,
              deparmentSingular,
              departmentPlural,
            ),
          ) as TextsWrapper
        ).default as Texts;

      case 'en':
        var texts: string = JSON.stringify(this.en);
        return (
          JSON.parse(
            this.replaceTexts(
              texts,
              adminSingular,
              adminPlural,
              userSingular,
              userPlural,
              deparmentSingular,
              departmentPlural,
            ),
          ) as TextsWrapper
        ).default as Texts;

      default:
        var texts: string = JSON.stringify(this.de);
        return (
          JSON.parse(
            this.replaceTexts(
              texts,
              adminSingular,
              adminPlural,
              userSingular,
              userPlural,
              deparmentSingular,
              departmentPlural,
            ),
          ) as TextsWrapper
        ).default as Texts;
    }
  }

  replaceTexts(
    text: string,
    adminSingular: string,
    adminPlural: string,
    userSingular: string,
    userPlural: string,
    deparmentSingular: string,
    departmentPlural: string,
  ): string {
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.AdminSingular, 'g'), adminSingular);
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.AdminPlural, 'g'), adminPlural);
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.UserSingular, 'g'), userSingular);
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.UserPlural, 'g'), userPlural);
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.DepartmentSingular, 'g'), deparmentSingular);
    text = text.replace(new RegExp(LanguageTextsReplacementMarker.DepartmentPlural, 'g'), departmentPlural);
    return text;
  }
}

export enum LanguageTextsReplacementMarker {
  AdminSingular = '#AdminSingular#',
  AdminPlural = '#AdminPlural#',
  UserSingular = '#UserSingular#',
  UserPlural = '#UserPlural#',
  DepartmentSingular = '#DepartmentSingular#',
  DepartmentPlural = '#DepartmentPlural#',
}
