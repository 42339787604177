import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrimeNG } from 'primeng/config';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { Texts } from '../language/texts';
import { LanguageService } from '../language/language-service';
import { ConfigService } from 'app/core/config/config.service';
import { Translation } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private language: string = 'de';

  private texts: Texts = {} as Texts;

  private isLanguageSwitchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentTenantName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private languageTextsService: LanguageService,
    private readonly configService: ConfigService,
    private primeng: PrimeNG,
  ) {
    this.language = this.configService.getLanguageAbbreviation();
  }

  getTexts(): Texts {
    if (this.texts.AdminDashboardTitle == undefined) {
      this.texts = this.languageTextsService.getTexts(
        this.language,
        'Admin',
        'Admins',
        this.configService.getAdminPortalUserNameSingular(),
        this.configService.getAdminPortalUserNamePlural(),
        this.configService.getAdminPortalDepartmentNameSingular(),
        this.configService.getAdminPortalDepartmentNamePlural(),
      );
    }
    return this.texts;
  }

  getClientLangauge(): string | null {
    var clientLanguage = navigator.language || (navigator.languages && navigator.languages[0]) || null;
    return clientLanguage;
  }

  setLanguage(language: string): void {
    if (language === undefined || language === null) return;

    var clientLanguage = this.getClientLangauge();

    if (clientLanguage !== null && !clientLanguage.includes(language)) {
      var languageFormLocalStorage = localStorage.getItem(SESSION_STORAGE_KEYS.CLIENT_LANGUAGE);

      if (languageFormLocalStorage !== null) {
        language = languageFormLocalStorage;
      }

      this.isLanguageSwitchEnabled.next(true);
    }

    if (language !== this.language) {
      this.texts = {} as Texts;
      if (language === 'de') {
        this.language = 'de';
      } else if (language === 'en') {
        this.language = 'en';
      } else this.language = 'en';
    }

    this.getTexts();

    this.primeng.setConfig({
      translation: this.getPrimeNgLanguageData()
    });
  }

  getPrimeNgLanguageData(reloadTexts: boolean = false): Translation {

    if (reloadTexts) {
      this.getTexts();
    }

    return {
      firstDayOfWeek: 1,
      monthNames: [
        this.texts.January,
        this.texts.February,
        this.texts.March,
        this.texts.April,
        this.texts.May,
        this.texts.June,
        this.texts.July,
        this.texts.August,
        this.texts.September,
        this.texts.October,
        this.texts.November,
        this.texts.December,
      ],
      monthNamesShort: [
        this.texts.PrimeNgMonthNamesShort[0],
        this.texts.PrimeNgMonthNamesShort[1],
        this.texts.PrimeNgMonthNamesShort[2],
        this.texts.PrimeNgMonthNamesShort[3],
        this.texts.PrimeNgMonthNamesShort[4],
        this.texts.PrimeNgMonthNamesShort[5],
        this.texts.PrimeNgMonthNamesShort[6],
        this.texts.PrimeNgMonthNamesShort[7],
        this.texts.PrimeNgMonthNamesShort[8],
        this.texts.PrimeNgMonthNamesShort[9],
        this.texts.PrimeNgMonthNamesShort[10],
        this.texts.PrimeNgMonthNamesShort[11],
      ],
      dayNames: [
        this.texts.PrimeNgDayNames[0],
        this.texts.PrimeNgDayNames[1],
        this.texts.PrimeNgDayNames[2],
        this.texts.PrimeNgDayNames[3],
        this.texts.PrimeNgDayNames[4],
        this.texts.PrimeNgDayNames[5],
        this.texts.PrimeNgDayNames[6],
      ],
      dayNamesShort: [
        this.texts.PrimeNgDayNamesShort[0],
        this.texts.PrimeNgDayNamesShort[1],
        this.texts.PrimeNgDayNamesShort[2],
        this.texts.PrimeNgDayNamesShort[3],
        this.texts.PrimeNgDayNamesShort[4],
        this.texts.PrimeNgDayNamesShort[5],
        this.texts.PrimeNgDayNamesShort[6],
      ],
      dayNamesMin: [
        this.texts.SundayMin,
        this.texts.MondayMin,
        this.texts.TuesdayMin,
        this.texts.WednesdayMin,
        this.texts.ThursdayMin,
        this.texts.FridayMin,
        this.texts.SaturdayMin,
      ],
      dateFormat: this.texts.PrimeNgDateFormat,
      today: this.texts.PrimeNgToday,
      weekHeader: this.texts.PrimeNgWeekHeader,
      weak: this.texts.PrimeNgWeak,
      medium: this.texts.PrimeNgMedium,
      strong: this.texts.PrimeNgStrong,
      passwordPrompt: this.texts.PrimeNgPasswordPrompt,
      emptyMessage: this.texts.PrimeNgEmptyMessage,
      emptyFilterMessage: this.texts.PrimeNgEmptyFilterMessage,
      pending: this.texts.PrimeNgPending,
      chooseYear: this.texts.PrimeNgChooseYear,
      chooseMonth: this.texts.PrimeNgChooseMonth,
      chooseDate: this.texts.PrimeNgChooseDate,
      prevDecade: this.texts.PrimeNgPrevDecade,
      nextDecade: this.texts.PrimeNgNextDecade,
      prevYear: this.texts.PrimeNgPrevYear,
      nextYear: this.texts.PrimeNgNextYear,
      prevMonth: this.texts.PrimeNgPrevMonth,
      nextMonth: this.texts.PrimeNgNextMonth,
      prevHour: this.texts.PrimeNgPrevHour,
      nextHour: this.texts.PrimeNgNextHour,
      prevMinute: this.texts.PrimeNgPrevMinute,
      nextMinute: this.texts.PrimeNgNextMinute,
      prevSecond: this.texts.PrimeNgPrevSecond,
      nextSecond: this.texts.PrimeNgNextSecond,
      am: this.texts.PrimeNgAm,
      pm: this.texts.PrimeNgPm,
      searchMessage: this.texts.PrimeNgSearchMessage,
      selectionMessage: this.texts.PrimeNgSelectionMessage,
      emptySelectionMessage: this.texts.PrimeNgEmptySelectionMessage,
      emptySearchMessage: this.texts.PrimeNgEmptySearchMessage,
      startsWith: this.texts.PrimeNgStartsWith,
      contains: this.texts.PrimeNgContains,
      notContains: this.texts.PrimeNgNotContains,
      endsWith: this.texts.PrimeNgEndsWith,
      equals: this.texts.PrimeNgEquals,
      notEquals: this.texts.PrimeNgNotEquals,
      noFilter: this.texts.PrimeNgNoFilter,
      lt: this.texts.PrimeNgLt,
      lte: this.texts.PrimeNgLte,
      gt: this.texts.PrimeNgGt,
      gte: this.texts.PrimeNgGte,
      is: this.texts.PrimeNgIs,
      isNot: this.texts.PrimeNgIsNot,
      before: this.texts.PrimeNgBefore,
      after: this.texts.PrimeNgAfter,
      dateIs: this.texts.PrimeNgDateIs,
      dateIsNot: this.texts.PrimeNgDateIsNot,
      dateBefore: this.texts.PrimeNgDateBefore,
      dateAfter: this.texts.PrimeNgDateAfter,
      clear: this.texts.PrimeNgClear,
      apply: this.texts.PrimeNgApply,
      matchAll: this.texts.PrimeNgMatchAll,
      matchAny: this.texts.PrimeNgMatchAny,
      addRule: this.texts.PrimeNgAddRule,
      removeRule: this.texts.PrimeNgRemoveRule,
      accept: this.texts.PrimeNgAccept,
      reject: this.texts.PrimeNgReject,
      choose: this.texts.PrimeNgChoose,
      upload: this.texts.PrimeNgUpload,
      cancel: this.texts.PrimeNgCancel,
      fileSizeTypes: this.texts.PrimeNgFileSizeTypes,
      aria: {
        trueLabel: this.texts.PrimeNgAria.trueLabel,
        falseLabel: this.texts.PrimeNgAria.falseLabel,
        nullLabel: this.texts.PrimeNgAria.nullLabel,
        star: this.texts.PrimeNgAria.star,
        stars: this.texts.PrimeNgAria.stars,
        selectAll: this.texts.PrimeNgAria.selectAll,
        unselectAll: this.texts.PrimeNgAria.unselectAll,
        close: this.texts.PrimeNgAria.close,
        previous: this.texts.PrimeNgAria.previous,
        next: this.texts.PrimeNgAria.next,
        navigation: this.texts.PrimeNgAria.navigation,
        scrollTop: this.texts.PrimeNgAria.scrollTop,
        moveTop: this.texts.PrimeNgAria.moveTop,
        moveUp: this.texts.PrimeNgAria.moveUp,
        moveDown: this.texts.PrimeNgAria.moveDown,
        moveBottom: this.texts.PrimeNgAria.moveBottom,
        moveToTarget: this.texts.PrimeNgAria.moveToTarget,
        moveToSource: this.texts.PrimeNgAria.moveToSource,
        moveAllToTarget: this.texts.PrimeNgAria.moveAllToTarget,
        moveAllToSource: this.texts.PrimeNgAria.moveAllToSource,
        pageLabel: this.texts.PrimeNgAria.pageLabel,
        firstPageLabel: this.texts.PrimeNgAria.firstPageLabel,
        lastPageLabel: this.texts.PrimeNgAria.lastPageLabel,
        nextPageLabel: this.texts.PrimeNgAria.nextPageLabel,
        prevPageLabel: this.texts.PrimeNgAria.prevPageLabel,
        rowsPerPageLabel: this.texts.PrimeNgAria.rowsPerPageLabel,
        previousPageLabel: this.texts.PrimeNgAria.previousPageLabel,
        jumpToPageDropdownLabel: this.texts.PrimeNgAria.jumpToPageDropdownLabel,
        jumpToPageInputLabel: this.texts.PrimeNgAria.jumpToPageInputLabel,
        selectRow: this.texts.PrimeNgAria.selectRow,
        unselectRow: this.texts.PrimeNgAria.unselectRow,
        expandRow: this.texts.PrimeNgAria.expandRow,
        collapseRow: this.texts.PrimeNgAria.collapseRow,
        showFilterMenu: this.texts.PrimeNgAria.showFilterMenu,
        hideFilterMenu: this.texts.PrimeNgAria.hideFilterMenu,
        filterOperator: this.texts.PrimeNgAria.filterOperator,
        filterConstraint: this.texts.PrimeNgAria.filterConstraint,
        editRow: this.texts.PrimeNgAria.editRow,
        saveEdit: this.texts.PrimeNgAria.saveEdit,
        cancelEdit: this.texts.PrimeNgAria.cancelEdit,
        listView: this.texts.PrimeNgAria.listView,
        gridView: this.texts.PrimeNgAria.gridView,
        slide: this.texts.PrimeNgAria.slide,
        slideNumber: this.texts.PrimeNgAria.slideNumber,
        zoomImage: this.texts.PrimeNgAria.zoomImage,
        zoomIn: this.texts.PrimeNgAria.zoomIn,
        zoomOut: this.texts.PrimeNgAria.zoomOut,
        rotateRight: this.texts.PrimeNgAria.rotateRight,
        rotateLeft: this.texts.PrimeNgAria.rotateLeft,
        listLabel: this.texts.PrimeNgAria.listLabel,
        selectColor: this.texts.PrimeNgAria.selectColor,
        removeLabel: this.texts.PrimeNgAria.removeLabel,
        browseFiles: this.texts.PrimeNgAria.browseFiles,
        maximizeLabel: this.texts.PrimeNgAria.maximizeLabel,
      },
    };
  }

  getLanguage(): string {
    return this.language;
  }

  getIsLanguageSwitchEnabled(): Observable<boolean> {
    return this.isLanguageSwitchEnabled.asObservable();
  }

  getCurrentTenantNameObs(): Observable<string> {
    return this.currentTenantName.asObservable();
  }

  getCurrentTenantName(): string {
    return this.currentTenantName.getValue();
  }

  setCurrentTenantName(tenantName: string): void {
    this.currentTenantName.next(tenantName);
  }
}
